import * as Yup from 'yup';
import { useState, useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import axios from 'axios';
import { toast } from 'react-toastify';
import Iconify from '../../../components/Iconify';
// ----------------------------------------------------------------------

export default function ForgetForm() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  async function sendotp(email) {
    try {
      if (!email) return toast.error('Please Enter Email');
      const { data } = await axios.post('/api/auth/otp', { email });
      toast.success("Otp sent successfull")
    } catch (e) {
      if (e.response.data) toast.error(e.response.data);
      else toast.error(e.message);
    }
  }

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    otp: Yup.string().min(6, 'otp is required').max(6, 'otp is required').required('otp is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async ({ email, otp, password }, { setSubmitting }) => {
      try {
        await axios.post('/api/auth/forgot-password', { email, otp, password });
        toast.success("Password reset successful");
        navigate('/login', { replace: true });
      } catch (e) {
        if (e.response.data) {
          toast.error(e.response.data);
        } else {
          toast.error(e.message);
        }
      }
      setSubmitting(false);
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const sendOtpClicked = async () => {
    setLoading(true);
    await sendotp(formik.values.email);
    setLoading(false);
  };
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LoadingButton onClick={sendOtpClicked} fullWidth size="large" variant="contained" loading={loading}>
                    Send Otp
                  </LoadingButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <TextField
            fullWidth
            autoComplete="username"
            type="text"
            label="Otp"
            {...getFieldProps('otp')}
            error={Boolean(touched.otp && errors.otp)}
            helperText={touched.otp && errors.otp}
          />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Reset Password
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
