import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import axiosInterceptor,{ getuser} from './redux/action/useraction';
import AuthProvider from './context/AuthProvider';
// ----------------------------------------------------------------------
export default function App() {
  const check = useSelector((state) => state.user.check);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getuser());
  }, [dispatch]);
  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <AuthProvider>
      {check && <Router />}
      </AuthProvider>
    </ThemeProvider>
  );
}
