import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
// material
import { Container, TextField, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import {post} from 'axios'
import {toast} from 'react-toastify'
import Page from '../components/Page';
import { el } from 'date-fns/locale';

export default function AddDevice() {
  const [loading, setLoading] = useState(false);
  const Schema = Yup.object().shape({
    value: Yup.string().required('Serial number required'),
    label: Yup.string().required('Device name required'),
  });
  const formik = useFormik({
    initialValues: {
      value: '',
      label: '',
    },
    validationSchema: Schema,
    onSubmit: (values) => {
      addDeviceClicked(values);
    },
  });
  const addDeviceClicked = async (values) => {
    try {
      const { data } = await post(process.env.REACT_APP_BASE_URL+'/api/users/device/add', values);
      console.log(data);
      toast.success("Device added");
    } catch (e) {
      if(e.response.status== 400&&e.response.data){
        toast.error(e.response.data.message);
      }
      else{
        toast.error(e.message);
      }
    }
  };
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <Page title="Add Device">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Add new Device
        </Typography>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Device Name"
                  {...getFieldProps('label')}
                  error={Boolean(touched.label && errors.label)}
                  helperText={touched.label && errors.label}
                />
                <TextField
                  fullWidth
                  label="Serial id"
                  {...getFieldProps('value')}
                  error={Boolean(touched.value && errors.value)}
                  helperText={touched.value && errors.value}
                />
              </Stack>
              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={loading}>
                Add device
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
