import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
const axiosInterceptor = axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response && error.response.status >= 400 && error.response.status < 500;
  if (expectedError) {
    // if (error.response && error.response.data) toast.error(error.response.data.message);
    if(error.response && error.response.status === 401) {
      // 
      Cookies.remove('Authorization');
      window.location = '/login';
    }
  }
  else toast.error('An unexpected error occurred.');
  return Promise.reject(error);
});
export default axiosInterceptor;
export const getuser = () => async (dispatch) => {
  try {
    const token = Cookies.get('Authorization');
    if (token) {
      const decoded = jwtDecode(token, { complete: true });
      // token expired time is greater than current time
      if ( decoded.exp * 1000 > Date.now()) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        dispatch({ type: 'LOGIN', payload: decoded });
      } else {
        dispatch({ type: 'SET_USER_CHECK' });
      }
    } else dispatch({ type: 'SET_USER_CHECK' });
  } catch (err) {
    dispatch({ type: 'SET_USER_CHECK' });
  }
};
