import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

const ACCESS_TOKEN_EXPIRES_TIME = 10 * 60 * 1000; // 10 min
export default function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  async function updateRefreshtoken() {
    const refreshToken = Cookies.get('refresh_token');
    if (!refreshToken) return;
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/refresh-token`, {
        refresh_token: refreshToken,
      });
      Cookies.set('access_token', data.access_token);
      Cookies.set('refresh_token', data.refresh_token);
      dispatch({ type: 'LOGIN', payload: data });
    } catch (e) {
      Cookies.remove('access_token');
      Cookies.remove('refresh_token');
      dispatch({ type: 'LOGOUT' });
      console.log(e.message);
    }
  }

  useEffect(() => {
    updateRefreshtoken();
    const interval = setInterval(() => updateRefreshtoken(), ACCESS_TOKEN_EXPIRES_TIME);
    return () => clearInterval(interval);
  }, []);
  return <>{children}</>;
}
