import { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, Grid, Button, Tooltip, Paper, Stack, Card, CardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
// sections
import { DevicesList, DeviceDetails, RealTime } from '../sections/@dashboard/app';
// components
import Page from '../components/Page';
import AqiTable from '../sections/@dashboard/app/AqiTable';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function index() {
  const [devices, setDevices] = useState([]);
  const [device, setDevice] = useState(false);
  const [deviceName, setDeviceName] = useState('');
  useEffect(() => {
    // setDeviceName(devices.devices[device]);
  }, [device]);
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axios.get(process.env.REACT_APP_BASE_URL + `/api/users/devices`,{params:{ page: 1, limit: 50 }});
        setDevices(data.data);
        if (data?.data?.length) setDevice(data?.data[0].device_id);
      } catch (e) {
        console.log(e.message);
      }
    }
    fetchData();
  }, []);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <DevicesList devices={devices} setDevice={setDevice} device={device} />
        {device ? (
          <>
            <RealTime device={device} />
            <DeviceDetails device={device} />
          </>
        ) : null}
        <Grid item xs={12}>
          <AqiTable />
        </Grid>
      </Container>
    </Page>
  );
}
