const initState = {
  check: false,
  authenticated: false,
  name: '',
  roles: [],
  language: 'ENGLISH',
};
const userReducer = (state = initState, action=false) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        authenticated: true,
        check: true,
        name: action.payload.name,
        roles: action.payload.roles,
      };
    case 'LOGOUT':
      return {
        ...initState,
        check: true,
      };
    case 'SET_LANGUAGE':
      return {
        ...state,
        language: action.payload,
      };
    case 'SET_USER_CHECK':
      return { ...state, check: true };
    default:
      return { ...state };
  }
};
export default userReducer;
