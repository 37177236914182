import { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton,Button,Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
// components
import MenuPopover from '../../components/MenuPopover';
// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'ENGLISH',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg',
  },
  {
    value: 'HINDI',
    label: 'Hindi',
    icon: '/static/icons/india-flag-icon.svg',
  },
];
const LANG = {
  ENGLISH: '/static/icons/ic_flag_en.svg',
  HINDI: '/static/icons/india-flag-icon.svg',
};

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const language = useSelector((state) => state.user.language);
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
    {/* <Typography variant="h6" component="h6" sx={{color:"#000"}}>
    Language 
    </Typography> */}
      <Button
        ref={anchorRef}
        onClick={handleOpen}
        variant="contained"
        sx={{
          px:5,
          width: 44,
          height: 44,
          mr:3,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        {/* <img src={LANG[language]} alt={language} /> */}
        {language}
      </Button>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                dispatch({ type: 'SET_LANGUAGE', payload: option.value });
                handleClose();
              }}
            >
              {/* <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} /> */}

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
