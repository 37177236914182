import { useEffect, useState } from 'react';
import axios from 'axios';
import { InputLabel, Grid, Button, ButtonGroup, MenuItem, FormControl, Select, Card, Paper } from '@mui/material';
import Chart from '../../../components/Charts';

const names = {
  aqi: { name: 'AQI', icon: 'mdi:air-filter' },
  temp: { name: 'Temperature', icon: 'carbon:temperature-celsius' },
  pm1: { name: 'PM 1', icon: 'emojione:factory' },
  pm10: { name: 'PM 10', icon: 'iconoir:oil-industry' },
  pm25: { name: 'PM 2.5', icon: 'fluent:weather-duststorm-20-filled' },
  hum: { name: 'Humidity', icon: 'entypo:drop' },
  so2: { name: 'Sulfur dioxide', icon: 'emojione:factory' },
  no2: { name: 'Nitrogen dioxide', icon: 'ps:feedburner' },
  co: { name: 'Carbon monoxide', icon: 'mdi:molecule-co' },
  co2: { name: 'Carbon dioxide', icon: 'iwwa:co2' },
  o3: { name: 'Ozone', icon: 'simple-icons:vectorlogozone' },
  nh3: { name: 'Ammonia', icon: 'mdi:ammonia' },
  hcl: { name: 'Hydrogen chloride', icon: 'mdi:acid' },
  lux: { name: 'LUX', icon: 'mdi:lightbulb-on' },
  sound: { name: 'Sound', icon: 'mdi:volume-high' },
};
export default function Charts({ device }) {
  const [deviceDetails, setDeviceDetails] = useState(null);
  const [datakey, setDatakey] = useState('');
  async function fetchDeviceDetails() {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/users/devices/${device}`, {
        params: { device_id: device },
      });
      setDatakey(data.sensors[0]);
      setDeviceDetails(data);
    } catch (e) {
      setDeviceDetails(null);
    }
  }
  useEffect(() => {
    setDeviceDetails(null);
    setDatakey('');
    setState([]);
    if (device) {
      fetchDeviceDetails();
    }
  }, [device]);

  const [loading, setLoading] = useState(true);
  const [chartType, setChartType] = useState('area');
  const [interval, setInterval] = useState('1h');
  const [state, setState] = useState([]);
  async function fetchDevice() {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/users/devices/getdata`, {
        params: { device_id: device, interval },
      });
      console.log('data', data);
      setState(data);
    } catch (e) {
      console.log(e.message);
    }
  }

  useEffect(() => {
    if (device && deviceDetails) {
      fetchDevice();
    }
  }, [device, interval, deviceDetails]);
  return (
    <Card>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <FormControl sx={{ m: 1, minWidth: 150 }}>
          <InputLabel id="select-parameter-label">Select Parameter</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select"
            label="Select Parameter"
            value={datakey}
            onChange={(event) => setDatakey(event.target.value)}
            autoWidth
          >
            {deviceDetails?.sensors?.map((item, idx) => (
              <MenuItem key={idx} value={item}>
                {names[item].name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <ButtonGroup variant="outlined" aria-label="outlined button group">
          <Button onClick={() => setChartType('area')}>Area</Button>
          <Button onClick={() => setChartType('bar')}>Bar</Button>
        </ButtonGroup>
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          <InputLabel id="interval-label">Interval</InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={interval}
            onChange={(event) => setInterval(event.target.value)}
            autoWidth
            label="interval-label"
          >
            <MenuItem value={'1h'}>1 Hour</MenuItem>
            <MenuItem value={'1d'}>1 Day</MenuItem>
            <MenuItem value={'7d'}>7 Days</MenuItem>
            <MenuItem value={'28d'}>28 Days</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Chart chartData={state} datakey={datakey} chartType={chartType} />
      {deviceDetails?.location ? (
        <Paper sx={{ w: 1 }}>
          <iframe
            title="map"
            loading="lazy"
            width="100%"
            style={{ height: '30vh' }}
            allowFullScreen
            referrerpolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_MAPS_KEY}&q=${deviceDetails.location.coordinates[1]},${deviceDetails.location.coordinates[0]}`}
          />
        </Paper>
      ) : null}
    </Card>
  );
}
