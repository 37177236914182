import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useEffect, useState } from 'react';
// @mui
import {  CardHeader, Box } from '@mui/material';
// components
import { BaseOptionChart } from './chart';
// ----------------------------------------------------------------------
Chart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
};
const datakeydict = {
  aqi: 'Aqi',
  hum: 'Humidity',
  temp: 'Tempreature',
  pm1: 'PM 1',
  pm10: 'PM 10',
  pm25: 'PM 25',
  o3: 'Ozone',
  co2: 'Carbon dioxide',
  so2: 'Sulfur dioxide',
  co: 'Carbon monoxide',
  no2: 'Nitrogen dioxide',
};
const unitkeydict = {
  hum: '%',
  temp: '(°C)',
  pm1: '(ug/m3)',
  pm10: '(ug/m3)',
  pm25: '(ug/m3)',
  o3: '(ppb)',
  co2: '(ppm)',
  so2: '(ppb)',
  co: '(ppb)',
  no2: '(ppb)',
};
export default function Chart({  chartData,datakey='aqi',chartType, ...other }) {
  const [data, setData] = useState([]);
  const [plotOptions,setPlotoption]=useState({area: {
        fillTo: 'origin',
    }})
  useEffect(()=>{
    if(chartType==='area')
    setPlotoption({area: {
        fillTo: 'origin',
    }})
    else
    setPlotoption({bar: { columnWidth: '16%' }})
  },[chartType])
  useEffect(() => {
    const l = [];
    if (chartData && datakey !== '') chartData.forEach((value) => l.push({ x: value.time, y: parseInt(value[datakey]) }));
    setData(l);
  }, [datakey, chartData]);
  const chartOptions = merge(BaseOptionChart(), {
    plotOptions,
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return value;
        },
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        datetimeUTC: false,
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        show: true,
        format: 'dd MMMM, h:mm:ss tt',
      },
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)}`;
          }
          return y;
        },
      },
    },
  });
  
  return (
    <>
      <CardHeader title={`${datakeydict[datakey]} ${unitkeydict[datakey]??''}`} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        {chartType == 'area' ? (
          data.length != 0 ? (
            <ReactApexChart
              type="area"
              series={[
                {
                  name: datakeydict[datakey],
                  data,
                },
              ]}
              options={chartOptions}
              height={364}
            />
          ) : (
            <h1> No Data Found</h1>
          )
        ) : data.length != 0 ? (
          <ReactApexChart
            type="bar"
            series={[
              {
                name: datakeydict[datakey],
                data,
              },
            ]}
            options={chartOptions}
            height={364}
          />
        ) : (
          <h1> No Data Found</h1>
        )}
      </Box>
    </>
  );
}
