import {useParams} from 'react-router-dom'
import {Grid,Box,Card,CardContent,Typography,Stack,TextField,Button } from '@mui/material';
import {useState} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import { useNavigate } from 'react-router-dom';
export default function EditDevice() {
  const navigate = useNavigate()
  const {device_id,deviceName}=useParams()
    const [newName,setNewName]=useState('')
  async function removeDevice(){
    try {
      await axios.delete(process.env.REACT_APP_BASE_URL+`/api/users/devices/${device_id}`);
      toast.success("Device removed succefully");
      navigate('/')
    } catch (e) {
      toast.error(e.response.data);
      return false;
    }
  }
  async function renameDevice(){
    try {
      const { data } = await axios.post(process.env.REACT_APP_BASE_URL+'/api/users/renamedevice', { device_id, newName });
      toast.success(data);
    } catch (e) {
      toast.error(e.response.data);
    }
  }
  return (
    <Grid
  container
  direction="column"
  justifyContent="center"
  alignItems="center"
  spacing={2}
>
    <Grid item xs>
     <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
      <CardContent>
      <Typography align='center' variant="h5"  gutterBottom>
        Device Details
      </Typography>
      <Stack
  direction="row"
  justifyContent="space-between"
  alignItems="center"
>
    <Typography variant="h5"  gutterBottom>
        Device Name
      </Typography>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" >
        {deviceName}
      </Typography>
    </Stack>
    <Stack
  direction="row"
  justifyContent="space-between"
  alignItems="center"
>
    <Typography variant="h5"  gutterBottom>
        Device ID
      </Typography>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" >
        {device_id}
      </Typography>
    </Stack>
      </CardContent>
      </Card>
    </Box>
    </Grid>
    <Grid item xs>
     <Box sx={{ minWidth: 275 }}>
      <Card variant="outlined">
      <CardContent>
      <Typography align='center' variant="h5"  gutterBottom>
        Device Details
      </Typography>
      <Stack
  direction="row"
  justifyContent="space-between"
  alignItems="center"
  spacing={2}
>
    <Typography variant="h5"  gutterBottom>
        New Name
      </Typography>
      <TextField onChange={(e)=>setNewName(e.target.value)} label={deviceName} variant="filled" />
    </Stack>
    <Stack
    sx={{pt:1}}
  direction="row"
  justifyContent="space-between"
  alignItems="center"
  spacing={2}
>
        <Button onClick={removeDevice} variant="contained" sx={{backgroundColor:'#5e5e5e'}}>
        Remove Device
      </Button>
      <Button onClick={renameDevice} variant="contained" color="success">
        Save
      </Button>
    </Stack>
      </CardContent>
      </Card>
    </Box>
    </Grid>
</Grid>
  )
}
