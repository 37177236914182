import * as Yup from 'yup';
import { useState, useContext } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
// ----------------------------------------------------------------------
import { post } from 'axios';
import { toast } from 'react-toastify';
import Iconify from '../../../components/Iconify';


export default function RegisterForm() {
  const [loading, setLoading] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Too Short!').max(50, 'Too Long!').required('Name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    otp: Yup.string().min(6, 'Invalid Otp').max(6, 'Invalid Otp').required('Otp is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      otp: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      registerdClicked(values);
    },
  });
  const registerdClicked = async (values) => {
    try {
      const { data } = await post(`${process.env.REACT_APP_BASE_URL}/api/auth/register`, formik.values);
      toast.success('Registration Successful');
      navigate('/login', { replace: true });
    } catch (e) {
      if (e.response) toast.error(e.response.data);
      else toast.error(e.message);
    } finally {
      setRegisterLoading(false);
    }
  };
  const sendOtpClicked = async () => {
    try {
      setLoading(true);
      const { data } = await post(`${process.env.REACT_APP_BASE_URL}/api/auth/otp`, {
        email: formik.values.email,
      });
      toast.success("Otp sent to your email");
    } catch (e) {
      console.log(e?.response?.data);
      if (e.response) toast.error(e.response.data);
      else console.log(e);
    } finally {
      setLoading(false);
    }
  };
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Name"
              autoComplete="name"
              {...getFieldProps('name')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
          </Stack>
          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="Otp"
              {...getFieldProps('otp')}
              error={Boolean(touched.otp && errors.otp)}
              helperText={touched.otp && errors.otp}
            />

            <LoadingButton onClick={sendOtpClicked} fullWidth size="large" variant="contained" loading={loading}>
              Send Otp
            </LoadingButton>
          </Stack>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={registerLoading}>
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
