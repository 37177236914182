// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
    admin: false,
  },
  {
    title: 'exportfile',
    path: '/dashboard/exportfile',
    icon: getIcon('foundation:page-export-csv'),
    admin: false,
  },
  {
    title: 'adddevice',
    path: '/dashboard/adddevice',
    icon: getIcon('carbon:add'),
    admin: false,
  },
  {
    title: 'issuedevice',
    path: '/dashboard/issuedevice',
    icon: getIcon('carbon:issue'),
    admin: true,
  },
];

export default navConfig;
