// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Grid } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(5),
  height: theme.spacing(5),
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
}));

// ----------------------------------------------------------------------

SensorWidget.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  field: PropTypes.string,
  measurement: PropTypes.string,
  sx: PropTypes.object,
};
function aqiColor(aqi) {
  if (aqi >= 401) {
    return 'maroon';
  }
  if (aqi >= 301) {
    return 'red';
  }
  if (aqi >= 201) {
    return 'rgb(255, 126, 0)';
  }
  if (aqi >= 101) {
    return 'yellow';
  }
  if (aqi >= 51) {
    return 'rgb(121, 185, 68)';
  }
  if (aqi >= 0) {
    return 'rgb(5, 172, 5)';
  }
}

export default function SensorWidget({ title, total, icon, unit, measurement, field, color = 'primary', sx, ...other }) {
  switch (field) {
    case 'temp':
      total = total.toFixed(1);
      break;
    case 'hum':
      total = Math.ceil(total);
      break;
    case 'pm10':
      total = Math.ceil(total);
      break;
    case 'pm25':
      total = Math.ceil(total);
      break;
    case 'pm1':
      total = Math.ceil(total);
      break;
    default:
      break;
  }

  if(['co','no2','so2','o3'].includes(field)&&unit==='ppb'){
    total = parseFloat(total.toFixed(1));
  }
  else if(['co','no2','so2','o3'].includes(field)&&unit==='ppm'){
    total = parseFloat(total.toFixed(4));
  }

  if (!['hum', 'temp', 'pm10', 'pm25', 'pm1', 'co', 'no2', 'so2', 'o3'].includes(field)) {
    switch (unit) {
      case 'ppb':
        total = Math.ceil(total);
        break;
      case 'ug/m3':
        total = parseFloat(total?.toFixed(2));
        break;
      // case 'mg/m3':
      //   total = total * 1000000;
      //   break;
      default:
        total = parseFloat(total?.toFixed(4));
        break;
    }
  }

 
  return (
    <Grid item xs={6} sm={3} md={2}>
      <Card
        sx={{
          py: 2,
          boxShadow: 0,
          textAlign: 'center',
          height: '100%',
          color: (theme) => theme.palette[color].darker,
          bgcolor: title === 'AQI' ? aqiColor(total) : (theme) => theme.palette[color].lighter,
          ...sx,
        }}
        {...other}
      >
        {icon && (
          <IconWrapperStyle
            sx={{
              color: (theme) => theme.palette[color].dark,
              backgroundImage: (theme) =>
                `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
                  theme.palette[color].dark,
                  0.24
                )} 100%)`,
            }}
          >
            <Iconify icon={icon} width={24} height={24} />
          </IconWrapperStyle>
        )}
        <Typography variant="h3">{total} </Typography>

        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {title}&nbsp;
          {unit ? `(${unit})` : null}
        </Typography>
      </Card>
    </Grid>
  );
}
