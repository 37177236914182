import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import AddDevice from './pages/AddDevice';
import DashboardApp from './pages/DashboardApp';
import ExportFile from './pages/ExportFile';
import EditDevice from './pages/EditDevice';
import ForgetPassword from './pages/ForgetPassword';
import IssueDevice from './pages/IssueDevice';
import DeviceDetails from './pages/DeviceDetails';
// ----------------------------------------------------------------------

export default function Router() {
  const auth = useSelector((state) => state.user.authenticated);
  const roles = useSelector((state) => state.user.roles);
  return useRoutes([
    // {
    //   path:"/",
    //   element:<>Home </>,
    // },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { index: true, element: auth ? <DashboardApp /> : <Navigate to="/login" /> },
        { path: 'app', index: true, element: auth ? <DashboardApp /> : <Navigate to="/login" /> },
        {
          path: 'details/:deviceId/:deviceName',
          element: auth ? <DeviceDetails /> : <Navigate to="/login" />,
        },
        { path: 'adddevice', element: auth ? <AddDevice /> : <Navigate to="/login" /> },
        { path: 'exportfile', element: auth ? <ExportFile /> : <Navigate to="/login" /> },
        {
          path: 'issuedevice',
          element: roles.includes('ADMIN') ? <IssueDevice /> : <Navigate to="/dashboard/app" />,
        },
        { path: 'editdevice/:device_id/:deviceName', element: auth ? <EditDevice /> : <Navigate to="/login" /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: !auth ? <Login /> : <Navigate to="/dashboard" /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: 'forgetpassword', element: auth ? <Navigate to="/dashboard/app" /> : <ForgetPassword /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
