import { Typography, Grid } from '@mui/material';
import Device from '../../../components/Device';

export default function DevicesList({ devices, device, setDevice }) {
  return (
    <Grid container spacing={2} sx={{ m: 1 }}>
      {devices.length ? (
        <Grid
          container
          spacing={3}
          sx={{ maxHeight: '60vh', background: '#baffc9', overflowY: 'auto', borderRadius: '10px', p: 1 }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {devices?.map((item) => (
            <Device device={device} setDevice={setDevice} key={item.device_id} name={item.label} {...item} />
          ))}
        </Grid>
      ) : (
        <Typography variant="h4">No Devices Found</Typography>
      )}
    </Grid>
  );
}
