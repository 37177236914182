import { useState } from 'react';
import { Checkbox, FormControl, FormLabel, FormControlLabel, Grid, Input } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/AddBox';
import { post } from 'axios';
import { toast } from 'react-toastify';

const params = [
  'aqi',
  'co',
  'co2',
  'hum',
  'no2',
  'o3',
  'pm1',
  'pm10',
  'pm25',
  'so2',
  'temp',
  'nh3',
  'hcl',
  'sound',
  'lux',
];
export default function IssueDevice() {
  const [state, setState] = useState({ device: '', selected: new Set(), loading: false, device_id: '' });
  const [available, setAvailable] = useState(params);
  const { selected } = state;
  const handleChange = (event) => {
    console.log(event.target.name, event.target.checked, state);
    if (event.target.checked) selected.add(event.target.name);
    else selected.delete(event.target.name);
  };
  async function issueANewDevice() {
    try {
      console.log(state);
      const { data } = await post(`${process.env.REACT_APP_BASE_URL}/api/admin/device`, {
        sensors: Array.from(selected),
        factors: {},
        device_id: state.device,
      });
      toast.success('Device Issued');
    } catch (e) {
      console.log(e);
      if (e?.response?.data?.message) {
        if (typeof e.response.data.message === 'string') {
          toast.error(e.response.data.message);
        } else if (Array.isArray(e.response.data.message)) {
          e.response.data.message.forEach((item) => {
            toast.error(item);
          });
        }
        else {
          toast.error('Something went wrong');
          console.log(e);
        }
      }
    }
  }
  return (
    <div>
      <FormControl sx={{ m: 3 }}>
        <FormLabel component="legend">Enter Device uuid</FormLabel>
        <Input
          id="my-input"
          aria-describedby="my-helper-text"
          onChange={(e) => setState({ ...state, device: e.target.value })}
        />
      </FormControl>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Select Parameters</FormLabel>
        <Grid container justifyContent="center" alignItems="center">
          {params.map((item) => (
            <FormControlLabel control={<Checkbox onChange={handleChange} name={item} />} label={item} />
          ))}
        </Grid>
        <LoadingButton
          loading={state.loading}
          loadingPosition="start"
          variant="outlined"
          onClick={() => issueANewDevice()}
          startIcon={<SaveIcon />}
        >
          {state.loading ? 'Issueing Device' : 'Issue Device'}
        </LoadingButton>
      </FormControl>
    </div>
  );
}
