import { Card, Stack, CardContent, Button, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import languages from '../utils/language.json';

export default function Device({ device, name, aqi, setDevice, device_id, _time, realtime, online }) {
  const language = useSelector((state) => state.user.language);
  return (
    <Grid item xs>
      <Card align="center" sx={{ background: device == device_id ? '#a3ec50' : '#c5e1a5' }}>
        <CardContent>
          <Stack direction="column" justifyContent="space-between" alignItems="start">
            <Typography variant="h5">
              {name} {online ? '🟢' : '🔴'}
            </Typography>
            <Typography variant="h6">{device_id}</Typography>
            {/* sx={{ color:aqi>50?'#e95a5a':'#57ff24' }}  */}
            {/* {aqi ? <Typography variant="subtitle2">AQI: {aqi}</Typography> : null} */}
          </Stack>
          {/* <Typography>{realtime?.length ? new Date(realtime[0]._time).toLocaleString() : 'No Data'}</Typography> */}
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Button
              color={device == device_id ? 'primary' : 'success'}
              variant="contained"
              size="small"
              onClick={() => setDevice(device_id)}
            >
              {languages[language].dashboard.deviceslist.details}
            </Button>
            <Button
              color="info"
              component={Link}
              to={`/dashboard/editdevice/${device_id}/${name}`}
              variant="contained"
              size="small"
            >
              {languages[language].dashboard.deviceslist.edit}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
}
