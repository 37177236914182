import React from 'react';

const tableStyles = {
  table: {
    border: '1px solid #000',
    borderCollapse: 'collapse',
    width: '100%',
  },
  caption: {
    textAlign: 'center',
    fontSize: 'larger',
    color: 'black',
  },
  th: {
    textAlign: 'center',
    verticalAlign: 'text-top',
    width: '114px',
    background: 'rgb(225, 235, 244)',
    color: 'black',
  },
  td: {
    textAlign: 'center',
    fontSize: '19px',
    fontFamily: 'bold',
  },
  trGood: {
    background: 'rgb(5, 172, 5)',
    color: 'black',
  },
  trModerate: {
    background: 'rgb(121, 185, 68)',
    color: 'black',
  },
  trSensitive: {
    background: 'yellow',
    color: 'black',
  },
  trUnhealthy: {
    background: 'rgb(255, 126, 0)',
    color: 'black',
  },
  trVeryUnhealthy: {
    background: 'red',
    color: 'white',
  },
  trHazardous: {
    background: 'maroon',
    color: 'white',
  },
};

export default function AqiTable() {
  return (
    <table style={tableStyles.table} className="table-bordered">
      <thead>
        <tr style={tableStyles.th}>
          <th scope="col" style={tableStyles.th}>
            AQI
          </th>
          <th scope="col" style={tableStyles.th}>
            Associated health impacts
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style={tableStyles.trGood}>
          <td id="good" style={tableStyles.td}>
            <strong>Good</strong>
            <br />
            <strong>(0 - 50)</strong>
          </td>
          <td style={tableStyles.td}>
            <strong>Minmal Impact</strong>
          </td>
        </tr>
        <tr style={tableStyles.trModerate}>
          <td id="mod" style={tableStyles.td}>
            <strong>Satisfactory</strong>
            <br />
            <strong>(51 - 100)</strong>
          </td>
          <td style={tableStyles.td}>
            <strong>Minor breathing discomfort to sensitive people </strong>
          </td>
        </tr>
        <tr style={tableStyles.trSensitive}>
          <td style={tableStyles.td}>
            <strong>Moderate</strong>
            <br />
            <strong>(101 - 200)</strong>
          </td>
          <td style={tableStyles.td}>
            <strong>Breathing discomfort to the people with lung, heart disease, children and older adults</strong>
          </td>
        </tr>
        <tr style={tableStyles.trUnhealthy}>
          <td style={tableStyles.td}>
            <strong>Poor</strong>
            <br />
            <strong>(201 - 300)</strong>
          </td>
          <td id="unh" style={tableStyles.td}>
            <strong>May cause breath discomfort to the people with lung disease such as asthma and discomfort to people with heart disease, children and older adults. </strong>
          </td>
        </tr>

        <tr style={tableStyles.trVeryUnhealthy}>
          <td style={tableStyles.td}>
            <strong>Very Poor</strong>
            <br />
            <strong>(301 - 400)</strong>
          </td>
          <td style={tableStyles.td}>
            <strong>May cause respiratory illness to the people on prolonged exposure. Effect may be more pronounced on people with lung and heart diseases.</strong>
          </td>
        </tr>

        <tr style={tableStyles.trHazardous}>
          <td style={tableStyles.td}>
            <strong>Severe</strong>
            <br />
            <strong>(401 - 500)</strong>
          </td>
          <td id="haz" style={tableStyles.td}>
            <strong>
              May cause respiratory effects even on healthy people and serious health impacts on people with lung/heart
              diseases. This health impacts may be experienced even during light physical activity.
            </strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
