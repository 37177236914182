import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';
// ----------------------------------------------------------------------
const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

const tableStyles = {
  table: {
    border: '2px solid #000',
    borderCollapse: 'collapse',
    width: '90%',
    margin: '5%',
    borderRadius: '10%',
  },
  caption: {
    textAlign: 'center',
    fontSize: 'larger',
    color: 'black',
  },
  th: {
    textAlign: 'center',
    verticalAlign: 'text-top',
    width: '114px',
    background: 'rgb(225, 235, 244)',
    color: 'black',
  },
  td: {
    textAlign: 'center',
    fontSize: '19px',
    fontFamily: 'bold',
  },
  trGood: {
    background: 'rgb(5, 172, 5)',
    color: 'black',
  },
  trModerate: {
    background: 'rgb(121, 185, 68)',
    color: 'black',
  },
  trSensitive: {
    background: 'yellow',
    color: 'black',
  },
  trUnhealthy: {
    background: 'rgb(255, 126, 0)',
    color: 'black',
  },
  trVeryUnhealthy: {
    background: 'red',
    color: 'white',
  },
  trHazardous: {
    background: 'maroon',
    color: 'white',
  },
};

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const user = useSelector((state) => state.user);
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>{/* <Logo /> */}</Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {user?.roles?.includes('ADMIN') ? 'Admin' : 'User'}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
          <table style={tableStyles.table} className="table-bordered">
            <thead>
              <tr style={tableStyles.th}>
                <th scope="col" style={tableStyles.th}>
                  AQI
                </th>
              </tr>
            </thead>
            <tbody>
              <tr style={tableStyles.trGood}>
                <td id="good" style={tableStyles.td}>
                  <strong>Good</strong>
                  <br />
                  <strong>(0 - 50)</strong>
                </td>
              </tr>
              <tr style={tableStyles.trModerate}>
                <td id="mod" style={tableStyles.td}>
                  <strong>Satisfactory</strong>
                  <br />
                  <strong>(51 - 100)</strong>
                </td>
              </tr>
              <tr style={tableStyles.trSensitive}>
                <td style={tableStyles.td}>
                  <strong>Moderate</strong>
                  <br />
                  <strong>(101 - 200)</strong>
                </td>
              </tr>
              <tr style={tableStyles.trUnhealthy}>
                <td style={tableStyles.td}>
                  <strong>Poor</strong>
                  <br />
                  <strong>(201 - 300)</strong>
                </td>
              </tr>

              <tr style={tableStyles.trVeryUnhealthy}>
                <td style={tableStyles.td}>
                  <strong>Very Poor</strong>
                  <br />
                  <strong>(301 - 400)</strong>
                </td>
              </tr>

              <tr style={tableStyles.trHazardous}>
                <td style={tableStyles.td}>
                  <strong>Severe</strong>
                  <br />
                  <strong>(401 - 500)</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </Drawer>
      )}
    </RootStyle>
  );
}
