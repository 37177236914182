import { useParams } from 'react-router-dom'
import { RealTime } from '../sections/@dashboard/app'
import Charts from '../sections/@dashboard/app/Charts'

export default function DeviceDetails() {
const {deviceId,deviceName}=useParams()
  return (
    <>
    <RealTime device={deviceId}/>
    <Charts device={deviceId} />  
      </>
  )
}
